.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sunyaLogo {
  height: 30px;
}

.headerText {
  color: white;
  margin-left: 10px;
}
