.buttonEffectShadow {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}

.buttonEffectShadow:hover,
.buttonEffectShadow:focus,
.buttonEffectShadow:active {
  box-shadow: 0px 6px 12px #6470fb59;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttonEffect {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.buttonEffect:hover,
.buttonEffect:focus,
.buttonEffect:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.activeButton {
  display: inline-flex !important;
  align-items: center !important ;
  justify-content: space-evenly !important;
  background: #3949ab !important;
  color: white !important;
  font-weight: 600 !important;
  position: relative !important;
  padding: 0 !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  font-size: 0.875rem !important;
}

.activeButtonHover:hover,
.activeButtonHover:focus,
.activeButtonHover:active {
  background: #232e6d !important;
}

.passiveButton {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #f2f2f2 !important;
  color: #303030 !important;
  font-weight: 600 !important;
  position: relative !important;
  padding: 0 !important;
  border-radius: 6px !important;
  box-shadow: -2px -6px 15px #fff, 2px 6px 15px rgba(0, 0, 0, 0.15) !important;
  cursor: pointer !important;
  font-size: 0.875rem !important;
}

.passiveButtonHover:hover,
.passiveButtonHover:focus,
.passiveButtonHover:active {
  background: #ececec !important;
}

.link {
  text-decoration: none;
  line-height: 1;
  position: relative;
  z-index: 0;
  display: inline-block;
  padding: 5px 5px;
  overflow: hidden;
  color: #2f4f4f;
  vertical-align: bottom;
  transition: color 0.3s ease-out;
  font-size: 1.1rem;
  font-weight: 600;
}

.link::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transform: translateY(calc(100% - 2px));
  width: 100%;
  height: 100%;
  background-image: linear-gradient(60deg, #3949ab 0%, #3949ab 100%);
  transition: transform 0.25s ease-out;
}

.link:hover {
  color: #fff;
}

.link:hover::before {
  transform: translateY(0);
  transition: transform 0.25s ease-out;
}

.section-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 20px;
  grid-gap: 20px;
}

.grid-span-12 {
  grid-column: span 12;
}

.grid-span-8 {
  grid-column: span 8;
}

.grid-span-6 {
  grid-column: span 6;
}

.grid-span-4 {
  grid-column: span 4;
}

@media only screen and (max-width: 1068px) {
  .grid-span-medium-12 {
    grid-column: span 12;
  }
}

@media only screen and (max-width: 1068px) and (min-width: 735px) {
}

.flexHorizontal {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.flexVertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deleteButton {
  cursor: pointer;
  color: gray;
}

.deleteButton:hover {
  color: red;
}

.editButton {
  cursor: pointer;
  color: gray;
}

.editButton:hover {
  color: #3949ab;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #e8e8e8;
  color: black;
  overflow: visible;
  font-family: inherit;
}

.ql-bubble .ql-editor pre.ql-syntax {
  background-color: #e8e8e8;
  color: black;
  overflow: visible;
  font-family: inherit;
}
