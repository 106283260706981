.filter-bar {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
}

.switch {
  display: flex;
  flex-direction: row !important;
  justify-content: end !important;
  margin-bottom: 20px;
}
