.addMemberButton {
  height: 40px;
  width: 150px;
  margin-bottom: 10px !important;
}

.form {
  display: flex;
  flex-direction: row;
}

.nameField {
  flex-basis: 40%;
  margin-right: 5px !important;
}

.emailField {
  flex-basis: 60%;
}

.cancelButton,
.addButton {
  height: 40px;
  width: 50px;
  font-family: unset !important;
  text-transform: none !important;
  font-weight: 600 !important;
}
